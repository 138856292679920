import React, { useState, useEffect } from "react"
import { useLocation } from "@reach/router"
import queryString from "query-string"
import { graphql } from "gatsby"
import Layout from "../../components/shared/layout"
import SEO from "../../components/SEO/SEOPage"
import Img from "gatsby-image"
import styled from "@emotion/styled"
import {
  wrapper1200,
  featuredWrapper,
  breakpoints,
  color,
} from "../../utils/style"
import Breadcrumbs from "../../components/shared/Breadcrumbs"
import Auth from "../../components/Auth/Auth"
import RegistrationFormStatic from "../../components/Auth/Form/RegistrationFormStaticCastrated"
import Parallax from "../../components/shared/Parallax"
import { lineCabinetRegister } from "../../components/Auth/Config"

const FeaturedWrapper = styled("div")`
  ${featuredWrapper};
  position: relative;
  margin: 15px 0;
  .banner-text {
    position: absolute;
    top: 30%;
    left: 25px;
    color: ${color.white};
    font-size: 34px;
  }
  .gatsby-image-wrapper {
    border-radius: 30px;
  }
  img {
    border-radius: 24px;
  }
  h1 {
    margin-top: 30px;
  }
  @media (max-width: ${breakpoints.md}) {
    .banner-text {
      font-size: 24px;
      top: 10%;
    }
    .gatsby-image-wrapper,
    img {
      border-radius: 10px;
    }
  }
  @media (max-width: ${breakpoints.xs}) {
    h1 {
      font-size: 1.5rem;
      margin-top: 20px;
      left: 20px;
    }
  }
`

const NoFeaturedWrapperReg = styled("div")`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  border-radius: 16px;
  padding: 20px 30px;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.1) 0%,
    transparent 100%
  );

  .top-block {
    flex-basis: 40%;
  }
  .top-block-right {
    flex-basis: 58%;
  }
  .auth-container {
    height: 50px;
    .auth-wrapper {
      text-align: left;
      width: auto;
      margin: 10px 0;
    }
    .button {
      margin-left: 0;
    }
  }
  @media (max-width: ${breakpoints.lg}) {
    .top-block {
      flex-basis: 100%;
    }
  }
  @media (max-width: ${breakpoints.md}) {
    padding: 20px 0;
    background: none;
  }
  @media (max-width: ${breakpoints.xs}) {
    padding: 0 0 20px 0;
    .top-block {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .auth-container {
        height: auto;
      }
    }
  }
`

const SaButtonWrap = styled("div")`
  padding-bottom: 35px;
  text-align: center;
  @media (max-width: ${breakpoints.xs}) {
    .button {
      white-space: normal;
      height: auto;
      padding: 12px 25px;
      width: 100%;
    }
  }
`

const WrapperDesktop = styled("div")`
  ${wrapper1200};
`

const ContentWrapper = styled("div")`
  ${wrapper1200};
  margin: 50px auto;
  ul {
    padding-left: 20px;
    letter-spacing: 0.75px;
    line-height: 160%;
  }

  ol {
    padding-left: 20px;
  }

  h2 {
    margin-bottom: 30px;
  }

  p {
    letter-spacing: 0.75px;
    margin: 15px 0;
  }
`

const PageTemplate = ({ data, pageContext }) => {
  const currentPage = data.wordpressPage
  const featuredMedia = currentPage.featured_media
  const metaTitle =
    currentPage.yoast_meta && currentPage.yoast_meta.yoast_wpseo_title
      ? currentPage.yoast_meta.yoast_wpseo_title
      : currentPage.title + "- " + process.env.OWNER
  const metaDesc =
    currentPage.yoast_meta && currentPage.yoast_meta.yoast_wpseo_metadesc
      ? currentPage.yoast_meta.yoast_wpseo_metadesc
      : ""
  const games = data.allWordpressFreespinGameslist.edges
  const breadcrumbs = pageContext.breadcrumbs
  const page_title = pageContext.page_title
  const bannerText = currentPage?.acf?.banner_text
  const socialImage = currentPage.featured_media?.localFile?.publicURL

  const location = useLocation()
  const parsedSearch = queryString.parse(location.search)
  const [registerFsOnly, setRegisterFsOnly] = useState(false)

  useEffect(() => {
    setRegisterFsOnly(
      "register_type" in parsedSearch &&
        parsedSearch.register_type === "onsitegames"
    )
  }, [parsedSearch])

  return (
    <Layout>
      <SEO
        title={metaTitle}
        description={metaDesc}
        pathname={currentPage.path}
        breadcrumbs={breadcrumbs}
        socialImage={socialImage}
      />
      <Parallax />
      <WrapperDesktop className="mytest">
        <Breadcrumbs breadcrumbs={breadcrumbs} page_title={page_title} />
        {featuredMedia &&
        featuredMedia.localFile &&
        featuredMedia.localFile.childImageSharp &&
        featuredMedia.localFile.childImageSharp.fluid ? (
          <div>
            <FeaturedWrapper>
              <Img
                fluid={featuredMedia.localFile.childImageSharp.fluid}
                alt={
                  currentPage.featured_media.alt_text
                    ? currentPage.featured_media.alt_text
                    : currentPage.title
                }
                fadeIn={false}
                loading="eager"
              />
              <h3
                className={"banner-text"}
                dangerouslySetInnerHTML={{ __html: bannerText }}
              />
              <h1 dangerouslySetInnerHTML={{ __html: page_title }} />
              <SaButtonWrap>
                <a
                  className="button gradient-button"
                  href={lineCabinetRegister}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  สมัครสมาชิกเล่นได้เงินจริงด้วย Lnwasia Wallet
                </a>
              </SaButtonWrap>
            </FeaturedWrapper>
            <NoFeaturedWrapperReg>
              <div className="top-block">
                <p>มีบัญชี?</p>
                <div className="auth-container">
                  <Auth hideRegButton={true} />
                </div>
              </div>
              {registerFsOnly && (
                <div className="top-block top-block-right">
                  <RegistrationFormStatic games={games} />
                </div>
              )}
            </NoFeaturedWrapperReg>
          </div>
        ) : (
          <NoFeaturedWrapperReg>
            <div className="top-block">
              <h1 dangerouslySetInnerHTML={{ __html: currentPage.title }} />
              <p>Play new games for free.</p>
              <div className="auth-container">
                <Auth hideRegButton={true} />
              </div>
            </div>
            {registerFsOnly && (
              <div className="top-block top-block-right">
                <RegistrationFormStatic games={games} />
              </div>
            )}
          </NoFeaturedWrapperReg>
        )}
      </WrapperDesktop>
      <ContentWrapper>
        <div dangerouslySetInnerHTML={{ __html: currentPage.content }} />
      </ContentWrapper>
    </Layout>
  )
}

export default PageTemplate

export const pageQuery = graphql`
  query($id: String!, $contentImages: [Int]) {
    wordpressPage(id: { eq: $id }) {
      wordpress_id
      title
      content
      date(formatString: "MMMM DD, YYYY")
      path
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
      acf {
        page_template
        banner_text
      }
      featured_media {
        alt_text
        localFile {
          publicURL
          childImageSharp {
            fluid(maxWidth: 1400) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
    allWordpressWpMedia(filter: { wordpress_id: { in: $contentImages } }) {
      edges {
        node {
          wordpress_id
          alt_text
          source_url
          localFile {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1400) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
                presentationWidth
              }
            }
          }
        }
      }
    }
    allWordpressFreespinGameslist {
      edges {
        node {
          code
          name
        }
      }
    }
  }
`
